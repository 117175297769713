/* global window */
/* global fbq */
(function (window, fbq) {

  const key = 'syg-cookie-consent';
  const valueDenied = 'denied';
  const valueAllowed = 'allowed';
  const id = 'cookie-warning';

  const consent = window.localStorage.getItem(key);
  const element = document.getElementById(id);

  if (consent === valueAllowed) {
    fbq('consent', 'grant');
  } else if (consent === valueDenied) {
    fbq('consent', 'revoke');
  } else {
    element.classList.add('cookie-warning--show');
  }

  window.acceptCookies = () => {
    window.localStorage.setItem(key, valueAllowed);
    fbq('consent', 'grant');
    element.classList.add('cookie-warning--hide');
    element.classList.remove('cookie-warning--show')
  }

  window.denyCookies = () => {
    window.localStorage.setItem(key, valueDenied);
    fbq('consent', 'revoke');
    element.classList.add('cookie-warning--hide');
    element.classList.remove('cookie-warning--show')
  }
})(window, fbq);
