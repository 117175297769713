/* global window */
/* global require */
(function (window) {
  function preloadImage(url, callback) {
    const image = new Image();
    image.src = url;
    image.onload = callback;
    image.onerror = function (error, data) {
      console.error(error, data);
    };
  }

  function fadeInImage(url, elementId) {
    preloadImage(url, function () {
      const backgroundImage = document.getElementById(elementId);
      backgroundImage.src = url;
      backgroundImage.style.opacity = '1';

      adaptImageHeight(elementId);
    });
  }

  function adaptImageHeight(elementId) {
    const backgroundImage = document.getElementById(elementId);
    backgroundImage.style.height = screen.availHeight + 'px';
  }

  function watchGrailWayVisibility(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        document.getElementById(entry.target.id).className += ' sig-bubble__animated';
      }
    });
  }

  function getBubbleElements() {
    return document.getElementsByClassName('sig-bubble');
  }

  function toggleScreen(screenId, on) {
    const imageElement = document.getElementById(screenId);

    imageElement.style.opacity = on ? '1' : '0';
  }

  function toggleBubble(bubbles, bubbleIndex) {
    if (selectedBubbleIndex != null) {
      toggleScreen('app-screen-' + (selectedBubbleIndex + 1), false);
      bubbles[selectedBubbleIndex].classList.remove('sig-bubble--selected');
    }

    if (bubbleIndex != null) {
      selectedBubbleIndex = bubbleIndex;

      toggleScreen('app-screen-' + (selectedBubbleIndex + 1), true);
      bubbles[selectedBubbleIndex].classList.add('sig-bubble--selected')
    } else {
      selectedBubbleIndex = null;

      toggleScreen('app-screen-0', true);
    }
  }

  function startSlideShow(bubbles) {
    stopSlideShow();
    screenChangeHandle = window.setInterval(function () {
      toggleBubble(bubbles, selectedBubbleIndex === null ? 0 : (selectedBubbleIndex + 1) % bubbles.length);
    }, screenChangeTimeout);
    slideShowInProgress = true;
  }

  function stopSlideShow() {
    if (screenChangeHandle !== null) {
      window.clearInterval(screenChangeHandle);
    }
    slideShowInProgress = false;
  }

  let selectedBubbleIndex = null;
  let screenChangeHandle = null;
  let slideShowInProgress = false;
  const screenChangeTimeout = 2500;

  window.initAppScreens = function () {
    const bubbles = getBubbleElements();
    for (let index = 0; index < bubbles.length; index++) {
      bubbles[index].addEventListener('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        stopSlideShow();
        toggleBubble(bubbles, index);
        startSlideShow(bubbles);
      });
      bubbles[index].addEventListener('mouseover', function (event) {
        event.stopPropagation();
        event.preventDefault();

        stopSlideShow();
        toggleBubble(bubbles, index);
      });
      bubbles[index].addEventListener('mouseout', function (event) {
        event.stopPropagation();
        event.preventDefault();

        if (slideShowInProgress) {
          return false;
        } else {
          startSlideShow(bubbles);

          return true;
        }
      });
    }
    window.setTimeout(function () {
      startSlideShow(bubbles);
    }, screenChangeTimeout);
  }

  window.loadBackground = function () {
    const backgroundUrlDesktop = require('../img/bg_01.png');
    const backgroundUrlMobile = require('../img/bg_01.png');

    fadeInImage(backgroundUrlDesktop, 'background-image-desktop');
    fadeInImage(backgroundUrlMobile, 'background-image-mobile');

    window.addEventListener('orientationchange', function () {
      adaptImageHeight('background-image-desktop');
      adaptImageHeight('background-image-mobile');
    });
  }
  window.animateGrailWay = function () {
    const observer = new IntersectionObserver(watchGrailWayVisibility, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    });
    const bubbles = getBubbleElements();
    for (let index = 0; index < bubbles.length; index++) {
      observer.observe(bubbles[index]);
    }

    toggleBubble(bubbles, null);
  }
})(window);

require('./cookie');
